import React, { useState } from "react"
import {
  useIntl,
  Link,
  IntlContextConsumer,
  changeLocale,
} from "gatsby-plugin-intl"

import "../styles/components/Header.scss"

import Logo from "../assets/images/logo.svg"
import Language from "../assets/images/icons/language.png"
import Menu from "../assets/images/icons/menu.png"
import CloseIcon from "../assets/images/icons/close_icon.svg"

const langs = ["es", "en", "br"];

export default function Header() {
  const intl = useIntl()
  const [mobile, setMobile] = useState(false)

  const handleChangeLanguage = (lang) => {
    changeLocale(lang);
  }

  return (
    <nav className="menu">
      <div className="menu__container">
        <Link to="/" className="menu__logo-container">
          <img src={Logo} alt="Logo" className="menu__logo" />
        </Link>
        <div className="menu__data">
          <ul className="menu__list">
            <li className="menu__item">
              <Link to="/about" className="menu__link">
                {intl.formatMessage({ id: "menu_item1" })}
              </Link>
            </li>
            <li className="menu__item">
              <Link to="/platform" className="menu__link">
                {intl.formatMessage({ id: "menu_item2" })}
              </Link>
            </li>
            <li className="menu__item">
              <Link to="/education" className="menu__link">
                {intl.formatMessage({ id: "menu_item3" })}
              </Link>
            </li>
            <li className="menu__item">
              <Link to="/market" className="menu__link">
                {intl.formatMessage({ id: "menu_item4" })}
              </Link>
            </li>
            <span className="menu__red">
              <li className="menu__item menu__item--button">
                <a
                  href="https://clientzone.Adrswap.com/login"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="menu__link"
                >
                  {intl.formatMessage({ id: "menu_item6" })}
                </a>
              </li>
              <li className="menu__item menu__item--button">
                <a
                  href="https://clientzone.Adrswap.com/register"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="menu__link"
                >
                  {intl.formatMessage({ id: "menu_item7" })}
                </a>
              </li>
              <li className="menu__item menu__item--button">
                <IntlContextConsumer>
                  {({ language }) => (
                    <div className="language">
                      <img src={Language} alt="" className="language__icon" />
                      {language}
                      <div className="language__menu">
                        {langs.map((lang) => (
                          <button
                            onClick={() => handleChangeLanguage(lang)}
                            className="language__menu-button"
                            type="button"
                            disabled={language === lang}
                          >
                            {lang.toUpperCase()}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </IntlContextConsumer>
              </li>
            </span>
          </ul>
          <button className="menu__show" onClick={() => setMobile(true)}>
            <img src={Menu} alt="Menu" className="menu__show-icon" />
          </button>
        </div>
        <div className={`menu__mobile${mobile ? " menu__mobile--active" : ""}`}>
          <button
            className={`menu__mobile-close${
              mobile ? " menu__mobile-close--active" : ""
            }`}
            onClick={() => setMobile(false)}
          >
            <img src={CloseIcon} alt="" className="menu__mobile-close-icon" />
          </button>
          <ul className="menu__mobile-list">
            <li className="menu__mobile-item menu__mobile-item--auth">
              <a
                href="https://clientzone.Adrswap.com/login"
                target="_blank"
                rel="noopener noreferrer"
                className="menu__mobile-link"
              >
                {intl.formatMessage({ id: "menu_item6" })}
              </a>
              <a
                href="https://clientzone.Adrswap.com/register"
                target="_blank"
                rel="noopener noreferrer"
                className="menu__mobile-link"
              >
                {intl.formatMessage({ id: "menu_item7" })}
              </a>
            </li>
            <li className="menu__mobile-item">
              <Link to="/about" className="menu__mobile-link">
                {intl.formatMessage({ id: "menu_item1" })}
              </Link>
            </li>
            <li className="menu__mobile-item">
              <Link to="/platform" className="menu__mobile-link">
                {intl.formatMessage({ id: "menu_item2" })}
              </Link>
            </li>
            <li className="menu__mobile-item">
              <Link to="/education" className="menu__mobile-link">
                {intl.formatMessage({ id: "menu_item3" })}
              </Link>
            </li>
            <li className="menu__mobile-item">
              <Link to="/market" className="menu__mobile-link">
                {intl.formatMessage({ id: "menu_item4" })}
              </Link>
            </li>
            <li className="menu__mobile-item menu__mobile-item--button">
              <IntlContextConsumer>
                {({ language }) => (
                  <div className="language">
                    <img src={Language} alt="" className="language__icon" />
                    {language}
                    <div className="language__menu">
                      <button
                        onClick={handleChangeLanguage(language)}
                        className="language__menu-button"
                        type="button"
                        disabled={language === "es"}
                      >
                        ES
                      </button>
                      <button
                        onClick={handleChangeLanguage(language)}
                        className="language__menu-button"
                        type="button"
                        disabled={language === "en"}
                      >
                        EN
                      </button>
                    </div>
                  </div>
                )}
              </IntlContextConsumer>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
