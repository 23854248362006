import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"

import "../styles/General.scss"

import SupportIcon from "../assets/images/icons/support-icon.svg"

import Header from "./Header.jsx"
import Footer from "./Footer.jsx"

const Layout = ({ children, pathname = "" }) => {
  const intl = useIntl()

  // useEffect(() => {
  //   const body = document.querySelector("body")
  //   const script = document.createElement("script")
  //   script.type = "text/javascript"
  //   script.textContent = `
  //     function initFreshChat() {
  //       window.fcWidget.init({
  //         token: "47da0fbc-8e45-4858-9857-0ba827c735fd",
  //         host: "https://wchat.freshchat.com"
  //       });
  //     }
  //     function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"Freshdesk Messaging-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);
  //   `
  //   body.appendChild(script)
  //   return () => {
  //     body.removeChild(script)
  //   }
  // }, [])

  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer />
      {/* {!(pathname === "/en/support" || pathname === "/es/support") ? (
        <a
          href="http://support.Adrswap.com"
          target="_blank"
          rel="noopener noreferrer"
          className="layout__support"
        >
          <img src={SupportIcon} alt="" className="layout__support-icon" />
          {intl.formatMessage({ id: "menu_item5" })}
        </a>
      ) : null} */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
