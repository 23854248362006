import React from "react"
import Logo from "../assets/images/logo.svg"
import { useIntl, Link } from "gatsby-plugin-intl"

import "../styles/components/Footer.scss"

export default function Footer() {
  const intl = useIntl()
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__box">
          <img src={Logo} alt="Logo" className="footer__logo" />
          <p className="footer__text">
            {intl.formatMessage({ id: "copyright" })}
          </p>
          <br />
          {/* <p className="footer__text">
            {intl.formatMessage({ id: "footer_text-extra" })}
          </p> */}
        </div>
        <div className="footer__box">
          <ul className="footer__list">
            <li className="footer__item">
              <Link to="/about" className="footer__link">
                {intl.formatMessage({ id: "menu_item1" })}
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/platform" className="footer__link">
                {intl.formatMessage({ id: "menu_item2" })}
              </Link>
            </li>
            {/* <li className="footer__item">
              <Link to="/education" className="footer__link">
                {intl.formatMessage({ id: "menu_item3" })}
              </Link>
            </li> */}
            <li className="footer__item">
              <Link to="/market" className="footer__link">
                {intl.formatMessage({ id: "menu_item4" })}
              </Link>
            </li>
            <li className="footer__item">
              <a
                href="http://support.Adrswap.com"
                target="_blank"
                rel="noopener noreferrer"
                className="footer__link"
              >
                {intl.formatMessage({ id: "menu_item5" })}
              </a>
            </li>
          </ul>
          <ul className="footer__list">
            <li className="footer__item">
              <Link to="/terms" className="footer__link">
                {intl.formatMessage({ id: "legals" })}
              </Link>
            </li>
            <li className="footer__item">
              <Link to="/contact" className="footer__link">
                {intl.formatMessage({ id: "contact" })}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
